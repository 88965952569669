body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  margin: 0;
  width: 100%;
  overflow-y: scroll;
}

.profile-image {
  border-radius: 50%;
  margin: 0;
}

a {
  color: black;
}

a:hover {
  color: #666;
  text-decoration: underline !important;
}

nav {
  margin-top: 25px;
  text-align: center; 
}

nav h1 {
  margin-bottom:10px;
}

nav ul {
  list-style-type: none; 
  padding: 0; 
  margin: 0; 
  display: flex; 
  justify-content: center; 
}

ul {
  list-style-type: none;
}

ul li {
  margin-bottom: 1rem;
  
}

nav ul li {
  display: block;
}

nav ul li a {
  padding: 10px; 
}

.container {
  margin: auto;
  width: 1200px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.main {
  width: 100%;

}

@media (max-width: 1240px) {
  .container {
    width: 1000px; 
    margin-top: 40px;
    margin-bottom: 40px;
  }
}


@media (max-width: 1024px) {
  .container {
    width: 768px; 
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%; 
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .container {
    width: 90%; 
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.home-container {
  display: flex;              
  justify-content: space-between;  
  align-items: stretch;
  gap: 5%;       
}

.home-container > div:first-child {
  flex: 0 0 40%;             
  display: flex;              
  justify-content: center;    
  align-items: center;        
}

.home-container > div:last-child {
  flex: 0 0 55%;              
}

.home-container > div:last-child > p:first-child {
  margin-top:0px !important;
}

.profile-image {
  max-width: 100%;            
  height: auto;               
}

@media (max-width: 768px) {
  .home-container {
    flex-direction: column; /* Stack the divs vertically */
    gap: 20px; /* Optional: Set a gap between the stacked divs */
  }

  .home-container > div:first-child,
  .home-container > div:last-child {
    flex: 0 0 auto; /* Allow divs to adjust their height */
    width: 100%; /* Ensure each div takes full width when stacked */
  }
}

footer {
  margin-bottom:25px;
}

ul {
  padding-left:20px;
}